export const colors = {
    'white': '#fff',
    'black': '#333',
    'dark': '#6b6b6b',
    'blue': '#01d8e9',
    'purple': '#8f56f8',
    'pink': '#ff177f',
    'yellow': '#ffc000',
    'gray': '#f5f5f5',
    'offblack': 'rgba(0,0,0,.75)',
    'offwhite': 'rgba(255,255,255,.75)',
    'purpleOverlay': 'rgba(143,86,248,.4)',
    'sandOverlay': 'rgba(226,135,67,.05)',
};

export const breakpoints = {
    'xs': '0px',
    'sm': '599px',
    'md': '959px',
    'lg': '1279px',
    'xl': '1919px',
};