import React from 'react';
import { Container } from '@mui/material';
import Statement from '../../components/statement';
import { DOWNLOAD_COPY } from '../../utils/strings/en_us';
import PromoImage from '../../assets/images/vortle_play_main.png';
import {
  StyledSection,
  StyledOverlay,
  StyledContainer,
  StyledButtonGroup,
  StyledExternalButton,
  StyledImageBackground,
} from './styled';

const Download = ({ id }) => {
  const { 
    PRODUCT_DESC,
    PRODUCT_TITLE,
    PRODUCT_PRETITLE,
    PRODUCT_APP_STORE_URL,
    PRODUCT_APP_STORE_IMG,
    PRODUCT_APP_STORE_ALT,
    PRODUCT_AMZN_STORE_URL,
    PRODUCT_AMZN_STORE_IMG,
    PRODUCT_AMZN_STORE_ALT,
    PRODUCT_GOOGLE_PLAY_URL,
    PRODUCT_GOOGLE_PLAY_IMG,
    PRODUCT_GOOGLE_PLAY_ALT,
    PRODUCT_STEAM_STORE_URL,
    PRODUCT_STEAM_STORE_IMG,
    PRODUCT_STEAM_STORE_ALT,
  } = DOWNLOAD_COPY;

  return (
    <StyledSection id={id}>
      <StyledOverlay>
        <Container>
          <StyledContainer>
            <Statement
              text={PRODUCT_DESC}
              title={PRODUCT_TITLE}
              pretitle={PRODUCT_PRETITLE}
              callToAction={<StyledButtonGroup>
                <StyledExternalButton href={PRODUCT_GOOGLE_PLAY_URL}>
                  <img alt={PRODUCT_GOOGLE_PLAY_ALT} src={PRODUCT_GOOGLE_PLAY_IMG} />
                </StyledExternalButton>
                <StyledExternalButton href={PRODUCT_APP_STORE_URL}>
                  <img alt={PRODUCT_APP_STORE_ALT} src={PRODUCT_APP_STORE_IMG} />
                </StyledExternalButton>
                <StyledExternalButton href={PRODUCT_AMZN_STORE_URL}>
                  <img alt={PRODUCT_AMZN_STORE_ALT} src={PRODUCT_AMZN_STORE_IMG} />
                </StyledExternalButton>
                <StyledExternalButton href={PRODUCT_STEAM_STORE_URL}>
                  <img alt={PRODUCT_STEAM_STORE_ALT} src={PRODUCT_STEAM_STORE_IMG} />
                </StyledExternalButton>
              </StyledButtonGroup>}
            />
            <StyledImageBackground image={PromoImage} />
          </StyledContainer>
        </Container>
      </StyledOverlay>
    </StyledSection>
  );
}

export default Download;
