import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { colors } from '../../utils/variables';
import { Container, Hidden } from '@mui/material';

import logo from '../../assets/images/logo.svg';
import symbol from '../../assets/images/symbol.svg';
import { 
  NAVIGATION, 
  IMAGE_DESCRIPTIONS 
} from '../../utils/strings/en_us';
import {
  StyledLogo,
  StyledButton,
  StyledSection,
  StyledContainer,
  StyledButtonGroup,
  StyledFeaturedButton,
} from './styled';

const Navigation = () => {
  const {
    LOGO_URL,
    ABOUT_ANCHOR,
    ABOUT_LABEL
  } = NAVIGATION;

  const handleAnchorEvent = id => { 
    const identifier = id.substring(1);
    const element = document.getElementById(identifier);
    window.history.pushState(null, null, identifier);
    if (element) element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  useEffect(() => {  
    const path = window.location.pathname;
    !isEmpty(path) && handleAnchorEvent(path)
  },[]);

  return (
    <StyledSection>
      <Container>
        <StyledContainer>
          <Hidden smDown>
            <StyledLogo 
              src={logo} 
              tabIndex='0'
              alt={IMAGE_DESCRIPTIONS.LOGO_ALT} 
              onClick={() => handleAnchorEvent(LOGO_URL)}
              onKeyPress={() => handleAnchorEvent(LOGO_URL)}
            />
          </Hidden>
          <Hidden smUp>
            <StyledLogo 
              src={symbol} 
              tabIndex='0'
              alt={IMAGE_DESCRIPTIONS.LOGO_ALT} 
              onClick={() => handleAnchorEvent(LOGO_URL)}
              onKeyPress={() => handleAnchorEvent(LOGO_URL)}
            />
          </Hidden>
          <StyledButtonGroup>
            {/* <StyledButton 
              tabIndex='0'
              onClick={() => handleAnchorEvent(RESEARCH_ANCHOR)}
              onKeyPress={() => handleAnchorEvent(RESEARCH_ANCHOR)}>
              <span>{RESEARCH_LABEL}</span>
            </StyledButton> */}
            {/* <StyledButton 
              tabIndex='0'
              onClick={() => handleAnchorEvent(PARTNERSHIPS_ANCHOR)}
              onKeyPress={() => handleAnchorEvent(PARTNERSHIPS_ANCHOR)}>
              <span>{PARTNERSHIPS_LABEL}</span>
            </StyledButton> */}
            <StyledButton 
              tabIndex='0'
              onClick={() => handleAnchorEvent(ABOUT_ANCHOR)}
              onKeyPress={() => handleAnchorEvent(ABOUT_ANCHOR)}>
              <span>{ABOUT_LABEL}</span>
            </StyledButton>
            {/* <StyledFeaturedButton 
              tabIndex='0'
              color={colors.black}
              onClick={() => handleAnchorEvent(DOWNLOAD_ANCHOR)}
              onKeyPress={() => handleAnchorEvent(DOWNLOAD_ANCHOR)} 
            >
              {DOWNLOAD_LABEL}
            </StyledFeaturedButton>   */}
          </StyledButtonGroup>
        </StyledContainer>
      </Container>
    </StyledSection>
  );
}

export default Navigation;
