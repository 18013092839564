import styled from 'styled-components';
import { colors, breakpoints } from '../../utils/variables';

export const StyledSection = styled.div`
    top: 0;
    width: 100%;
    z-index: 999;
    position: fixed;
    padding: 30px 0;
    background: ${colors.white};
`;

export const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const StyledButtonGroup = styled.div`
    display: inline-block;
`;

export const StyledLogo = styled.img`
    width: 150px;
    cursor: pointer;
    @media only screen and (max-width: ${breakpoints.sm}) {
        width: 40px;
    }
`;

export const StyledButton = styled.a`
    padding: 10px;
    margin: 0 10px;
    cursor: pointer;
    font-weight: 600;
    text-decoration: none;
    color: ${colors.dark};
    :hover { color: ${colors.black} } 
    @media only screen and (max-width: ${breakpoints.sm}) {
        margin: 0 5px;
    }
`;

export const StyledFeaturedButton = styled(StyledButton)`
    font-weight: 600;
    border-radius: 5px;
    padding: 10px 15px;
    color: ${colors.white};
    background: ${props => props.color};
    box-shadow: 0 10px 20px -4px rgb(25 25 25 / 30%);
    :hover {
        color: ${colors.white};
        box-shadow: 0 5px 10px -4px rgb(25 25 25 / 30%);
    }
    @media only screen and (max-width: ${breakpoints.sm}) {
        margin-left: 20px;
    }
    @media only screen and (max-width: 500px) {
        display: none;
    }
`;