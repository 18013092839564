import styled from 'styled-components';
import scene from '../../assets/scenes/lighthouse.jpg';
import { colors, breakpoints } from '../../utils/variables';

export const StyledSection = styled.div`
    width: 100%;
    margin-top: 95px;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    background-image: url(${scene});
`;

export const StyledOverlay = styled.div`
    padding: 120px 0;
    background: ${colors.purpleOverlay};
    @media only screen and (max-width: ${breakpoints.md}) {
        padding: 60px 0;
    }
`;

export const StyledContainer = styled.div`
    display: flex;
    > * {
        flex: 1;
        :first-child {
            margin-right: 50px;
        }
    }
    @media only screen and (max-width: ${breakpoints.md}) {
        display: block;
        > * {
            :first-child {
                margin: 0 15px;
            }
        }
    }
`;

export const StyledButtonGroup = styled.div`
    display: flex;
    flex-flow: wrap;
`;

export const StyledExternalButton = styled.a`
    flex: 1;
    img {
        height: 50px;
        margin-top: 20px;
        @media only screen and (max-width: ${breakpoints.md}) {
            margin-top: 0px;
        }
    }
`;

export const StyledImageBackground = styled.div`
border-radius: 10px;
border: 2px solid #333;
background: #333;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center right;
    background-image: url(${props => props.image});
    @media only screen and (max-width: ${breakpoints.md}) {
        width: 100%;
        margin-top: 30px;
        min-height: 250px;
        background-size: contain;
        background-position: bottom center;
    }
    @media only screen and (max-width: ${breakpoints.sm}) {
        min-height: 175px;
    }
`;