import React from 'react';
/* Page content sections
 */
import Download from './content/download';
/* Reusable app-wide components and variables
 */
import Footer from './components/footer';
import Navigation from './components/navigation';
import { NAVIGATION } from './utils/strings/en_us';
/* Theming and styles
 */
import { StyledApp, theme } from './StyledApp';
import { ThemeProvider } from '@mui/material/styles';

const App = () => {
  const {
    DOWNLOAD_URL,
  } = NAVIGATION;

  return (
    <StyledApp>
      <ThemeProvider theme={theme}>
        <header>
          <Navigation />
        </header>
        <Download id={DOWNLOAD_URL} />
        {/* <Promo /> */}
        <Footer />
      </ThemeProvider>
    </StyledApp>
  );
}

export default App;
