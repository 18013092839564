import styled from 'styled-components';
import { colors } from './utils/variables';
import '@fontsource/source-sans-pro/300.css';
import '@fontsource/source-sans-pro/400.css';
import '@fontsource/source-sans-pro/700.css';
import { createTheme } from '@mui/material/styles';

export const StyledApp = styled.div`
    color: ${colors.dark};
    font-family: 'Source Sans Pro';
`;

export const theme = createTheme({
    status: {},
});