import styled from 'styled-components';
import { colors, breakpoints } from '../../utils/variables';

export const StyledSection = styled.div`
    width: 100%;
    padding: 50px 0;
    color: ${colors.dark};
    background: ${colors.white};
`;

export const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: ${breakpoints.md}) {
        display: block;
    }
    @media only screen and (max-width: ${breakpoints.sm}) {
        text-align: center;
    }
`;

export const StyledItemContainer = styled.div`
    display: flex;
    align-items: center;
    @media only screen and (max-width: ${breakpoints.md}) {
        margin-bottom: 20px;
        justify-content: center;
    }
    @media only screen and (max-width: ${breakpoints.sm}) {
        display: block;
    }
`;

export const StyledLogo = styled.img`
    width: 40px;
    margin-right: 20px;
    @media only screen and (max-width: ${breakpoints.sm}) {
        display: block;
        margin: 0 auto 15px;
    }
`;

export const StyledLink = styled.a`
    text-decoration: none;
    color: ${colors.dark};
    :hover { opacity: .8 }
    @media only screen and (max-width: ${breakpoints.sm}) {
        display: block;
        margin: 10px 0;
    }
`;

export const StyledDivider = styled.span`
    opacity: .4;
    height: 20px;
    margin: 0 15px;
    border-right: solid 1px;
    @media only screen and (max-width: ${breakpoints.sm}) {
        display: none;
    }
`;

export const StyledIcon = styled.a`
    opacity: .8;
    margin-right: 5px;
    text-decoration: none;
    img { width: 30px }
    :hover { opacity: 1 }
`;
/* Hidden until feature release
 */
export const StyledDropdown = styled.button`
    display: none;

    margin-left: 30px;
    padding: 10px 20px;
    border-radius: 5px;
    background: transparent;
    color: ${colors.dark};
    border: solid 1px ${colors.dark};
    @media only screen and (max-width: ${breakpoints.sm}) {
        display: block;
        margin: 35px auto 0;

        display: none;
    }
`;