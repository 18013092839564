import styled from 'styled-components';
import '@fontsource/montserrat/700.css';
import { colors, breakpoints } from '../../utils/variables';

export const StyledSection = styled.div`
    color: ${colors.white};
`;

export const StyledText = styled.p`
    font-size: 100%;
`;

export const StyledImage = styled.img`
    width: 75px;
`;

export const StyledHeader = styled.h1`
    margin: 0;
    font-size: 275%;
    font-weight: 700;
    color: ${colors.white};
    font-family: 'Montserrat';
    @media only screen and (max-width: ${breakpoints.sm}) {
        font-size: 210%;
    }
`;

export const StyledPreHeader = styled.h2`
    font-size: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    @media only screen and (max-width: ${breakpoints.sm}) {
        font-size: 16px;
    }
`;

export const StyledContrastText = styled.h2`
    font-size: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
    background-color: #000000;
    padding: 10px;
    @media only screen and (max-width: ${breakpoints.sm}) {
        font-size: 14px;
    }
`;
