import React, { useState } from 'react';
import { Menu, Container, MenuItem } from '@mui/material';
import { FOOTER, LANGUAGES } from '../../utils/strings/en_us';

import symbol from '../../assets/images/symbol.svg';
import tiktok from '../../assets/icons/light/tiktok.svg';
import twitter from '../../assets/icons/light/twitter.svg';
import instagram from '../../assets/icons/light/instagram.svg';

import {
  StyledLogo,
  StyledIcon,
  StyledLink,
  StyledDivider,
  StyledSection,
  StyledDropdown,
  StyledContainer,
  StyledItemContainer,
} from './styled';

const Footer = () => {
  const {
    COPYRIGHT,
    TERMS_URL,
    TIKTOK_URL,
    TWITTER_URL,
    PRIVACY_URL,
    TIKTOK_ALT,
    TWITTER_ALT,
    TERMS_LABEL,
    INSTAGRAM_ALT,
    INSTAGRAM_URL,
    PRIVACY_LABEL,
  } = FOOTER;

  const {
    HINDI,
    ARABIC,
    CHINESE,
    ENGLISH,
    SPANISH,
    RUSSIAN,
    LANGUAGE,
    PORTUGUESE,
  } = LANGUAGES;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledSection>
      <Container>
        <StyledContainer>
          <StyledItemContainer>
            <StyledLogo src={symbol} />
            <span>{COPYRIGHT}</span>
            <StyledDivider />
            <StyledLink href={TERMS_URL}>{TERMS_LABEL}</StyledLink>
            <StyledDivider />
            <StyledLink href={PRIVACY_URL}>{PRIVACY_LABEL}</StyledLink>
          </StyledItemContainer>
          <StyledItemContainer>
            <StyledIcon href={TIKTOK_URL} target='_blank' rel='noreferrer'>
              <img src={tiktok} alt={TIKTOK_ALT} />
            </StyledIcon>
            <StyledIcon href={TWITTER_URL} target='_blank' rel='noreferrer'>
              <img src={twitter} alt={TWITTER_ALT} />
            </StyledIcon>
            <StyledIcon href={INSTAGRAM_URL} target='_blank' rel='noreferrer'>
              <img src={instagram} alt={INSTAGRAM_ALT} />
            </StyledIcon>
            <StyledDropdown
              aria-haspopup='true'
              onClick={handleClick}
              aria-controls='basic-menu'
              aria-expanded={open ? 'true' : undefined}
            > {LANGUAGE}
              </StyledDropdown>
            <Menu
              open={open}
              id='basic-menu'
              anchorEl={anchorEl}
              onClose={handleClose}
              MenuListProps={{ 'aria-labelledby': 'basic-button' }}
            >
              <MenuItem onClick={handleClose}>{ENGLISH}</MenuItem>
              <MenuItem onClick={handleClose}>{SPANISH}</MenuItem>
              <MenuItem onClick={handleClose}>{PORTUGUESE}</MenuItem>
              <MenuItem onClick={handleClose}>{RUSSIAN}</MenuItem>
              <MenuItem onClick={handleClose}>{ARABIC}</MenuItem>
              <MenuItem onClick={handleClose}>{CHINESE}</MenuItem>
              <MenuItem onClick={handleClose}>{HINDI}</MenuItem>
            </Menu>
          </StyledItemContainer>
        </StyledContainer>
      </Container>
    </StyledSection>
  );
}

export default Footer;
