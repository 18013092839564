import atlasLogo from '../../assets/partners/logo_atlas.png';
import bridgesLogo from '../../assets/partners/logo_bridges.png';
import appStoreIcon from '../../assets/images/app_store_icon.svg';
import googlePlayIcon from '../../assets/images/google_play_icon.svg';
import amznIcon from '../../assets/images/amazon_appstore_badge.svg';
import steamIcon from '../../assets/images/steam.svg';

export const IMAGE_DESCRIPTIONS = {
    LOGO_ALT: 'Vortle logo',
    LOGO_DESC: 'Vortle logo, colorful spheres forming a circle.'
};

export const NAVIGATION = {
    LOGO_URL: '#download',
    ABOUT_URL: 'about',
    ABOUT_ANCHOR: '#about',
    ABOUT_LABEL: 'About',
    RESEARCH_URL: 'research',
    RESEARCH_ANCHOR: '#research',
    RESEARCH_LABEL: 'Research',
    DOWNLOAD_URL: 'download',
    DOWNLOAD_ANCHOR: '#download',
    DOWNLOAD_LABEL: 'Download',
    PARTNERSHIPS_URL: 'partnerships',
    PARTNERSHIPS_ANCHOR: '#partnerships',
    PARTNERSHIPS_LABEL: 'Partnerships',
};

export const FOOTER = {
    TIKTOK_URL: 'https://www.tiktok.com/@vortleapp',
    INSTAGRAM_URL: 'https://www.instagram.com/vortleapp',
    TWITTER_URL: 'https://twitter.com/vortleapp',
    TIKTOK_ALT: 'TikTok logo',
    INSTAGRAM_ALT: 'Instagram logo',
    TWITTER_ALT: 'Twitter logo',
    PRIVACY_URL: 'https://www.vortle.com/misc/privacy.html',
    PRIVACY_LABEL: 'Privacy policy',
    TERMS_URL: 'https://www.vortle.com/misc/terms.html',
    TERMS_LABEL: 'Terms & conditions',
    COPYRIGHT: '© 2021 Vortle Inc.',
};

export const DOWNLOAD_COPY = {
    PRODUCT_TITLE: 'Video Games to Calm Down',
    PRODUCT_PRETITLE: 'Support Positive Mental Health',
    PRODUCT_DESC: "Sensory-friendly video games, meditation and breathing powered by AI.",
    PRODUCT_GOOGLE_PLAY_URL: 'https://play.google.com/store/apps/details?id=com.Vortle.SnowHome&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
    PRODUCT_GOOGLE_PLAY_IMG: googlePlayIcon,
    PRODUCT_GOOGLE_PLAY_ALT: 'Get it on Google Play',
    PRODUCT_APP_STORE_URL: 'https://apps.apple.com/us/app/vortle/id1573147829',
    PRODUCT_APP_STORE_IMG: appStoreIcon,
    PRODUCT_APP_STORE_ALT: 'Download on the Apple App Store',
    PRODUCT_STEAM_STORE_URL: 'https://store.steampowered.com/app/1791430/Vortle/',
    PRODUCT_STEAM_STORE_IMG: steamIcon,
    PRODUCT_STEAM_STORE_ALT: 'Download on Steam',
    PRODUCT_AMZN_STORE_URL: 'https://www.amazon.com/dp/B09K64QJ45',
    PRODUCT_AMZN_STORE_IMG: amznIcon,
    PRODUCT_AMZN_STORE_ALT: 'Download on the Amazon App Store',
};

export const RESEARCH = {
    RESEARCH_READ_MORE: 'Read article',
    RESEARCH_TITLE: 'Science on video games having positive impact on mental health',
    RESEARCH_FINEPRINT: 'Multiple research papers show that games act as psychological intervention during a fight-or-flight response episode, also games help with anxiety. ',
    RESEARCH_QUOTES: [{
        QUOTE: 'Beneficial effects of playing would be found even <span>four days after</span> analogue trauma was confirmed.',
        SOURCE: 'U.S. National Library of Medicine',
        URL: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5678449/',
    },{
        QUOTE: 'The number of intrusions per week was 12.6 at baseline and 6.1 over the intervention phase: <span>52% reduction</span>.',
        SOURCE: 'JMIR Formative Research',
        URL: 'https://preprints.jmir.org/preprint/29873?utm_source=TrendMD&utm_medium=cpc&utm_campaign=JMIR_Preprints_TrendMD_1',
    },{
        QUOTE: 'Children who played demonstrated a <span>39% reduction</span> in preoperative anxiety compared to controls.',
        SOURCE: 'National Center for Biotechnology Information',
        URL: 'https://pubmed.ncbi.nlm.nih.gov/31199333/',
    }],
};

export const PARTNERSHIPS = {
    PARTNERSHIPS_LABEL: 'Become a partner',
    PARTNERSHIPS_URL: 'https://oztnl1qpf5m.typeform.com/to/cn3btvIv',
    PARTNERSHIPS_TITLE: 'AI-based mental health toolbox for service providers.',
    PARTNERSHIPS_TEXT: "Partners have access to our advanced Artificial Intelligence and Machine Learning based analytics for tracking their patients' in-app behavior to be used for enhancing the service they provide.",
    PARTNERS: [{
        LOGO: bridgesLogo,
        LOGO_ALT: 'Bridges Learning System Logo',
        TITLE: 'Bridges Learning System',
        URL: 'https://bridgeslearningsystem.com',
        SUBTITLE: 'Creating innovative, community-driven educational curricula that empowers autistic and neurodivergent individuals.',
    },{
        LOGO: atlasLogo,
        LOGO_ALT: 'Atlas Logo',
        TITLE: 'Atlas',
        URL: 'https://www.seekatlas.com',
        SUBTITLE: 'Offering a robust process for matching individuals and highly-trained, area-focused therapists.',
    }],
};

export const TESTIMONIALS = [{
    CONTENT: 'I am a PTSD survivor and I can not easily enjoy many digital experiences as they often triggering, exclusive and worsen my mental health. ' + 
    'I have started Vortle with an idea of making most inclusive mental health product in the world.',
    AUTHOR: 'Stan Kirdey',
    TITLE: 'Founder',
},

{
    CONTENT: "It’s the app I didn’t know I needed. I wish I’d known about it sooner! It’s so soothing to play the games (which don’t keep score so I don’t feel pressured) and I love the visuals. It’s also the first app I’ve found with a circle breathing visualizer that isn’t tied to something paid. I can’t recommend this app enough for folks who experience panic attacks or PTSD.",
    AUTHOR: 'Vortle Player',
    TITLE: '',
}

];

export const LANGUAGES = {
    LANGUAGE: 'Language',
    ARABIC: 'عربي',
    CHINESE_SIMPLIFIED: '简体中文',
    HINDI: 'हिंदी',
    ENGLISH: 'English',
    SPANISH: 'Español',
    PORTUGUESE: 'Português',
    RUSSIAN: 'русский',
};