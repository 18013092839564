import React from 'react';
import {
  StyledText,
  StyledContrastText,
  StyledImage,
  StyledHeader,
  StyledSection,
  StyledPreHeader,
} from './styled';


const Statement = props => {
  const { text, image, title, pretitle, callToAction } = props;

  return (
    <StyledSection>
        <StyledImage src={image} />
        <StyledPreHeader>{pretitle}</StyledPreHeader>
        <StyledHeader>{title}</StyledHeader>
        <StyledContrastText>{text}</StyledContrastText>
        {callToAction}
    </StyledSection>
  );
}

export default Statement;
